<template>
  <ciam-page :title-suffix="deploymentName">
    <ciam-card>
      <ciam-card-header>
        <template v-slot:header>
          <ciam-card-content-line type="full-width" v-if="problem">
            <template v-slot:content>
              <ciam-alert :title="problem.title" :description="problem.detail" :type="AlertStatus.ALERT"></ciam-alert>
            </template>
          </ciam-card-content-line>
          <ciam-clipboard title="Copy deployment Id" :payload="deploymentId">{{ deploymentName }}</ciam-clipboard>
        </template>


        <template v-slot:subtitle>
          <status-badge :status="status"/>
        </template>
        <template v-slot:tabs>
          <ciam-tabs :tabs="tabs()"></ciam-tabs>
        </template>
      </ciam-card-header>
      <div v-if="deployment.isJust">
        <router-view :deployment="deployment.get()"></router-view>
      </div>
    </ciam-card>
  </ciam-page>
</template>

<script>
import CiamCard from '@/components/CiamCard';
import CiamCardHeader from '@/components/CiamCardHeader';
import StatusBadge from '@/components/StatusBadge';
import PlanService from '@/pages/PlanService';
import DeploymentService from '@/pages/deployments/DeploymentService';
import CiamTabs from '@/components/CiamTabs';
import router, { routeNames } from '@/router';
import Maybe from 'data.maybe-fgribreau';
import { pathOr } from 'ramda';
import CiamClipboard from '@/components/CiamClipboard.vue';
import CiamAlert, { AlertStatus } from '@/components/CiamAlert.vue';
import CommonService from '@/pages/commonService';

export default {
  props: {},
  name: 'Deployment',
  components: {
    CiamClipboard,
    CiamCard,
    CiamCardHeader,
    CiamTabs,
    StatusBadge,
    CiamAlert,
  },
  data() {
    return {
      deployment: Maybe.Nothing(),
      statistics: null,
      AlertStatus: AlertStatus,
      problem: null,
    };
  },
  beforeDestroy() {
    clearTimeout(this._timeout);
  },
  mounted() {
    this.refreshDeployment(this.$route.params.id, { displayLoader: true, continuousRefresh: true });
  },
  methods: {
    tabs() {
      let tabsBuilder = [];
      tabsBuilder.push({
        icon: 'fa-info',
        id: 'details',
        name: this.$t('deployment.details.tab'),
        route: {
          name: routeNames.DeploymentDetails,
        },
        default: true,
      });

      if (this.status !== 'CREATING') {
        tabsBuilder.push({
          icon: 'fa-chart-line',
          id: 'insights',
          name: this.$t('deployment.insights.tab'),
          route: {
            name: routeNames.DeploymentInsights,
          },
        });

        tabsBuilder.push({
          icon: 'fa-file-code',
          id: 'logs',
          name: this.$t('deployment.logs.tab'),
          route: {
            name: routeNames.DeploymentLogs,
          },
        });

        tabsBuilder.push({
          icon: 'fa-download',
          id: 'exports',
          name: this.$t('deployment.exports.tab'),
          route: {
            name: routeNames.DeploymentExports,
          },
        });

        tabsBuilder.push({
          icon: 'fa-wrench',
          id: 'configuration',
          name: this.$t('deployment.configuration.tab'),
          route: {
            name: routeNames.DeploymentConfigurations,
          },
        });

        tabsBuilder.push({
          icon: 'fa-calendar',
          id: 'audits',
          name: this.$t('deployment.audits.tab'),
          route: {
            name: routeNames.DeploymentAudits,
          },
        });
      }

      if (this.$keycloak.hasRealmRole('ADMIN')) {
        tabsBuilder.push({
          icon: 'fa-flask',
          id: 'advanced',
          name: this.$t('deployment.advanced.tab'),
          route: {
            name: routeNames.DeploymentAdvanced,
          },
        });
      }

      if (this.$keycloak.hasRealmRole('ADMIN')) {
        tabsBuilder.push({
          icon: 'fa-sitemap',
          id: 'instances',
          name: this.$t('deployment.instances.tab'),
          route: {
            name: routeNames.DeploymentInstances,
          },
        });
      }

      return tabsBuilder;
    },
    contactUs() {
      this.$router
        .push({
          name: routeNames.TicketList,
        })
        .catch(() => {
        });
    },

    /**
     * @param {string} adminUrl - keycloak admin URL
     */
    openKeycloakConsole() {
      window.open(this.adminUrl);
    },

    /**
     * @param {String} id
     * @param {Object} options
     */
    async refreshDeployment(id, options = { displayLoader: false, continuousRefresh: false }) {
      let loader;
      if (options.displayLoader) {
        loader = this.$loading.show();
      }

      return DeploymentService.get(id)
        .then((deployment) => {
          this.deployment = Maybe.Just(deployment);
          if (options.continuousRefresh) {
            this.continuousRefreshDeployment(id);
          }
          return deployment;
        })
        .catch((err) => {
          this.problem = err;
          if (this.problem.status === 404) {
            // the deployment does not exist
            router.push({ name: routeNames.DeploymentList }).catch(() => {
            });
            return;
          }

          this.$log.error('Error while fetching the deployment', err);
          this.deployment = Maybe.Nothing();
        })
        .finally(() => {
          if (options.displayLoader) {
            loader.hide();
          }
        });
    },

    /**
     * @param {String} id
     */
    continuousRefreshDeployment(id) {
      this._timeout = setTimeout(() => {
        this.refreshDeployment(id, { displayLoader: false, continuousRefresh: true });
      }, 3000);
    },
  },
  computed: {
    status() {
      return this.deployment.map((d) => d.status).getOrElse('');
    },
    deploymentId() {
      return this.deployment.map((d) => d.id).getOrElse('');
    },
    deploymentName() {
      return this.deployment.map((d) => d.name).getOrElse('');
    },
    showCustomExtensions() {
      return this.deployment.map((d) => PlanService.hasCustomExtensions(d)).getOrElse(false);
    },
  },
};
</script>


