<template>
  <span
    class="tag"
    v-if="status"
    :class="getStatus(status).style"
    :title="$t('deploymentDetail.status')"
    :data-e2e="'deployment-status-' + status"
    v-text="getTranslation(status)"
  />
</template>

<script>
export default {
  name: 'StatusBadge',
  data() {
    return {
      statusList: {
        RUNNING: {
          i18n: 'running',
          style: 'is-success is-light',
        },
        CREATING: {
          i18n: 'creating',
          style: 'animate-pulse is-primary is-light',
        },

        STARTING: {
          i18n: 'starting',
          style: 'animate-pulse is-warning is-light',
        },

        STOPPED: {
          i18n: 'stopped',
          style: 'is-info is-light',
        },

        ERROR: {
          i18n: 'error',
          style: 'is-danger is-light',
        },

        DELETING: {
          i18n: 'deleting',
          style: 'animate-pulse is-danger is-light',
        },

        UNKNOWN: {
          i18n: 'unknown',
          style: 'is-light',
        },
      },
    };
  },
  props: {
    status: {
      type: String,
      default: 'UNKNOWN',
      // we are lax here because the API could yield an unsupported status and we still want to fallback on "UNKNOWN" status state
    },
  },
  methods: {
    /**
     * @param {String} status
     * @return {String}
     */
    getTranslation(status) {
      return this.$t(`deployment.status.${this.getStatus(status).i18n}`);
    },

    /**
     * @param {String} status
     * @return {Object}
     */
    getStatus(status) {
      // eslint-disable-next-line no-prototype-builtins
      return this.statusList.hasOwnProperty(status) ? this.statusList[status] : this.statusList.UNKNOWN;
    },
  },
};
</script>
